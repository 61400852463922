.R3 {
	background-color: #F1948A;
}

.R2 {
	background-color: #F5B7B1;
}

.R1 {
	background-color: #FADBD8;
}

.S1 {
	background-color: #D4EFDF;
}

.S2 {
	background-color: #A9DFBF;
}

.S3 {
	background-color: #7DCEA0;
}

.stop {
	color: #78281F;
}

.tasa {
	color: #7D6608;
}

.profit {
	color: #145A32;
}
